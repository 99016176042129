@use "../variables";

*.m-0 {
  margin: 0!important;
}
*.p-0 {
  padding: 0;
}
.p-05 {
  padding: variables.$standard-spacer/2;
}
*.p-1 {
  padding: variables.$standard-spacer;
}
*.pt-0 {
  padding-top: 0;
}
*.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mr-1 {
  margin-right: variables.$standard-spacer;
}
*.py-0 {
  padding-top: 0!important;
  padding-bottom: 0!important;
}
*.px-0 {
  padding-left: 0!important;
  padding-right: 0!important;
}
*.px-05 {
  padding-left: variables.$standard-spacer/2;
  padding-right: variables.$standard-spacer/2;
}
*.px-1 {
  padding-left: variables.$standard-spacer;
  padding-right: variables.$standard-spacer;
}
*.py-05 {
  padding-top: variables.$standard-spacer/2;
  padding-bottom: variables.$standard-spacer/2;
}
*.py-1 {
  padding-top: variables.$standard-spacer;
  padding-bottom: variables.$standard-spacer;
}
*.pb-1 {
  padding-bottom: variables.$standard-spacer!important;
}
*.pb-2 {
  padding-bottom: variables.$standard-spacer * 2!important;
}
*.my-1 {
  margin-top: variables.$standard-spacer;
  margin-bottom: variables.$standard-spacer;
}
*.mt-1 {
  margin-top: variables.$standard-spacer;
}
*.mb-1 {
  margin-bottom: variables.$standard-spacer!important;
}
*.mt-0 {
  margin-top: 0;
}
*.mb-0 {
  margin-bottom: 0;
}

*.monospace {
  font-family: monospace;
}

*.show-pointer {
  cursor: pointer;
}

*.text-smaller {
  font-size: smaller;
}

*.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

*.v-align-middle {
  vertical-align: middle;
}

*.text-center {
  text-align: center;
}

.text-color-max {
  color: variables.$q90-095;
}

.green-text {
  color: variables.$q90-suc;
}

.red-text {
  color: variables.$q90-dan;
}
.gap-1 {
  gap: calc(variables.$standard-spacer/4);
}
.gap-2 {
  gap: calc(variables.$standard-spacer/2);
}
.gap-4 {
  gap: variables.$standard-spacer;
}
.flex {
  display: flex;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.transparent-05 {
  opacity: .5;
}
.inline-flex {
  display: inline-flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-center {
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.left-full {
  left: 100%;
}
.pl-2 {
  padding-left: 2rem;
}
.pl-2i {
  padding-left: 2rem !important;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-start {
  justify-content: start;
}
.justify-end {
  justify-content: end;
}
.w-full {
  width: 100%;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.text-1 {
  font-size: 10px;
}

.text-white-70 {
  color: rgba(255, 255, 255, .7);
}
.text-white {
  color: white;
}
.uppercase {
  text-transform: uppercase;
}
.block {
  display: block;
}
.-transform-x-50 {
  transform: translateX(-50%);
}

.bg-white {
  background: white;
}

.rounded-bottom-large {
  border-bottom-left-radius: variables.$standard-border-radius;
  border-bottom-right-radius: variables.$standard-border-radius;
}

.grid {
  display: grid;
}
.grid-cols-2 {
  grid-template-columns: 1fr 1fr;
}

.font-bold {
  font-weight: bold;
}
