@use "../variables.scss";

div#q90.layout-main {
  main {
    a:not(.button) {
      color: variables.$q90-040;
      &:hover {
        color: variables.$q90-pri;
      }
    }
  }
}
