@use "../variables";

.btn {
  background-color: black;
  padding: 4px 0.6375rem;
  font-size: 0.875rem;
  transition: background-color 300ms ease-in-out;
  cursor: pointer;
}

.btn-main {
  @extend .btn;
  background-color: variables.$main-blue;
  color: white;
  &:hover {
    background-color: darken(variables.$main-blue, 10);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: variables.$main-blue;
    opacity: .5;
  }

  &.with-icon {
    padding: 0 15px;

    span{ 
      margin: 0 5px;
    }
  }
}
