@use "../variables.scss";

div#q90.layout-no-sidebar {
  main {
    a {
      color: variables.$q90-pri;
      text-decoration: none;
      &:hover {
        color: variables.$t1;
      }
    }
  }
  footer#q90-footer {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
