@use "variables.scss";

// https://www.modularscale.com/?0.9375&rem&1.2 (minor third)
//https://dev.to/adrianbdesigns/mastering-css-vertical-rhythm-om9
html {
  font-size: variables.$typography-modular-html-base;
}

body {
  font-size: variables.$typography-modular-base;
  font-family: variables.$font-family;
  font-weight: 400;
  line-height: variables.$spacing-vertical-1;
  color: variables.$q90-040;
}

h1,
.h1 {
  font-size: variables.$typography-modular-1;
  line-height: variables.$spacing-vertical-1-5;
  font-weight: 700;
  margin-block: variables.$spacing-vertical-base;
  color: variables.$q90-000;
}

h2,
.h2 {
  font-size: variables.$typography-modular-2;
  line-height: variables.$spacing-vertical-1-5;
  font-weight: 600;
  margin-block: variables.$spacing-vertical-base;
  color: variables.$q90-000;
}

h3,
.h3 {
  font-size: variables.$typography-modular-3;
  line-height: variables.$spacing-vertical-1-5;
  font-weight: 600;
  margin-block: variables.$spacing-vertical-base;
  color: variables.$q90-000;
}

h4,
.h4 {
  font-size: variables.$typography-modular-4;
  line-height: variables.$spacing-vertical-1;
  font-weight: 600;
  margin-block: variables.$spacing-vertical-base;
  color: variables.$q90-000;
}

h5,
.h5 {
  font-size: variables.$typography-modular-5;
  line-height: variables.$spacing-vertical-1;
  font-weight: 600;
  margin-block: variables.$spacing-vertical-base 0;
  color: variables.$q90-000;
}

h6,
.h6 {
  font-size: variables.$typography-modular-6;
  line-height: variables.$spacing-vertical-1;
  font-weight: 600;
  margin-block: variables.$spacing-vertical-base 0;
  color: variables.$q90-000;
}

p,
.body {
  font-size: variables.$typography-modular-base;
  font-weight: 400;
  margin-block: variables.$spacing-vertical-base 0;
  color: inherit;
}

ul {
  font-size: variables.$typography-modular-base;
  font-weight: 400;
  margin-block: variables.$spacing-vertical-base 0;
  list-style-type: disc;
  list-style-position: inside;
  color: inherit;
}

ol {
  font-size: variables.$typography-modular-base;
  font-weight: 400;
  margin-block: variables.$spacing-vertical-base 0;
  list-style-position: inside;
  color: inherit;
}

li {
  font-size: variables.$typography-modular-base;
  font-weight: 400;
  margin-block: 0 0;
  color: inherit;
}

nav {
  margin-block: 0 0;
  a {
    text-decoration: none;
  }
}

section,
main {
  margin-block: 0 0;
}

table {
  * {
    font-size: variables.$typography-modular-base;
  }
}

form,
fieldset {
  margin-block: 0 0;
}

input,
select {
  font-size: variables.$typography-modular-base;
  height: variables.$spacing-vertical-1-5;
  line-height: variables.$spacing-vertical-1-5;
  color: inherit;
}

textarea {
  font-size: variables.$typography-modular-base;
  line-height: variables.$spacing-vertical-1;
  color: inherit;
}

label,
*.label {
  font-size: variables.$typography-modular-base;
  line-height: variables.$spacing-vertical-1-5;
  color: variables.$q90-000;
}

button,
.button {
  font-size: variables.$typography-modular-base;
  font-weight: 600;
}

strong,
.strong {
  font-weight: 600;
}

em {
  font-style: italic;
}
