// @import "primeng/resources/themes/lara-light-blue/theme.css";
// @import "primeng/resources/primeng.css";
@use "sass:meta";
@use "app/styles/variables.scss";
@import "app/styles/reset.css";
@include meta.load-css("app/styles/base.scss");
@include meta.load-css("app/styles/layouts/layout-main.scss");
@include meta.load-css("app/styles/layouts/layout-no-sidebar.scss");

a {
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
}

::-webkit-scrollbar {
  display: none;
}

div,
section,
nav,
article,
aside,
header {
  &.grid-layout {
    display: grid;
    gap: variables.$standard-spacer;
    &.no-gap {
      gap: 0;
    }
    &.no-gap-row {
      row-gap: 0;
    }
    &.no-gap-col {
      column-gap: 0;
    }
    &.small-gap {
      gap: 8px;
    }
    &.align-center {
      align-items: center;
    }
    &.justify-center {
      justify-items: center;
    }
    &.grid-colspan-none {
      grid-column: none;
    }
    &[class*="grid-cols-2"] {
      &.right-heavy {
        grid-template-columns: auto 1fr;
      }
      &.left-heavy {
        grid-template-columns: 1fr auto;
      }
      &.max-content {
        * {
          max-width: fit-content;
        }
      }
      &.flow {
        grid-auto-rows: auto;
      }
      &.spread {
        & > *:nth-child(even) {
          text-align: right;
          margin-right: 0;
        }
      }
    }
    &[class*="grid-cols-3"] {
      &.middle-heavy {
        grid-template-columns: auto 1fr auto;
      }
    }
    &.grid-cols-2.q90-divider,
    &.grid-cols-3.q90-divider {
      & > h4,
      & > h5 {
        @extend .long-text;
        @extend .my-0;
      }
    }
  }
  &.dark {
    * {
      color: white;
    }
  }
}

.flex-header {
  display: flex;
  flex-direction: row;
  gap: 8px;
  overflow: hidden;

  > * {
    flex: 0 0 auto;
  }

  :first-child {
    width: fit-content;
    flex-grow: 1;
  }

  :last-child {
    align-self: flex-end;
    flex-shrink: 2;
  }
}

div.flex-layout {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  &.no-gap {
    gap: 0;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.layout-vertical {
    flex-direction: column;
  }
  & > div {
    flex: 1;
    border: 1px solid black;
    border-radius: 3px;
    padding: 20px;
  }
  &.wrap {
    flex-wrap: wrap;
  }
}

button {
  &.is-transparent {
    background: transparent;
  }
  &.full-width {
    display: block;
  }
}

div.button-bar {
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: calc(variables.$standard-spacer/2);
  & + div.button-bar {
    margin-top: calc(variables.$standard-spacer/2);
  }
  &.nowrap {
    flex-wrap: nowrap;
  }
  & > button {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    &.grow-me {
      flex-grow: 1;
    }
  }
  &.align-center {
    text-align: center;
    justify-content: center;
  }
  &.align-left {
    text-align: left;
    justify-content: flex-start;
  }
  &.fill-width {
    button {
      flex-grow: 1;
    }
  }
}

.button-sidebar {
  button {
    display: block;
    width: 100%;
    margin-bottom: variables.$margin-m;
  }
  .spacer {
    margin: variables.$margin-xl;
  }
}

nav {
  padding: 0;
  margin-bottom: variables.$px-30/2;
  & > header {
    padding: 0 variables.$standard-spacer;
    margin-bottom: variables.$standard-spacer;
  }
  ul {
    margin-block: 0 0;
    list-style: none;
    &.horiz {
      li {
        display: inline-block;
        vertical-align: middle;
        padding-left: 0;
        padding-right: variables.$standard-spacer;
        a {
          color: variables.$t5;
          text-decoration: none;
          font-weight: 400;
          &.is-active {
            font-weight: 600;
            color: variables.$t1;
          }
        }
      }
      &.text-right {
        text-align: right;
        li {
          padding-right: 0;
          padding-left: variables.$standard-spacer;
        }
      }
    }
  }

  ol {
    & > header {
      padding: 0 variables.$standard-spacer;
    }
    margin-block: 0 0;
    list-style: none;
    &.horiz {
      li {
        display: inline-block;
        padding: 0.75rem;
      }
    }
  }
}

.q90-section,
section.q90-section {
  &:not(.clear) {
    background-color: variables.$t1;
  }

  position: relative;
  border-radius: variables.$standard-border-radius;
  margin-bottom: 0;
  padding: variables.$standard-spacer;
  &:not(.with-overflow) {
    max-height: variables.$main-height;
    overflow: hidden;
    overflow-y: scroll;
  }
  &.centered {
    @extend .centered-block;
    &.vertical {
      flex-direction: column;
      align-items: center;
    }
  }
  &.no-bg {
    background-color: inherit;
  }
  &.p-0 {
    padding: 0;
  }
  &.fill-height {
    height: 100%;
  }
  &.fit-content {
    height: fit-content;
  }
  div.error-message {
    border-radius: 0;
  }
  &:has(header),
  &:has(form) form:has(header) {
    display: flex;
    flex-direction: column;
    & > header {
      flex-grow: 0;
    }
    & > div.scrollable-content {
      background-color: variables.$t1;
      overflow: hidden;
      overflow-y: scroll;
      flex-grow: 1;
      min-height: variables.$px-30 * 3.5;
      padding-bottom: variables.$px-30/2;
    }
    & > footer {
      flex-grow: 0;
    }
  }
}

// .admin-module-header {
//   border-bottom: $standard-border;
//   padding: 0 15px;

//   h4 {
//     font-size: 16px;
//     font-weight: 500;
//     margin: 10px 0;
//   }

//   button,
//   .button {
//     justify-self: end;
//     margin: auto 0;
//   }
// }

// .admin-module-content {
//   padding: 10px 15px;
// }

// .dark-mode {
//   button {
//     background: $t5;

//     &:not(:disabled),
//     &:not(:disabled:hover) {
//       &:hover {
//         background: $q90-pri;
//       }
//     }
//   }
// }
