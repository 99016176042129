$font-family: "Baloo 2", sans-serif;

$typography-modular-small: .75rem;
$typography-modular-html-base: 0.875rem; // 0.9375em; //0.9375em; //15px
$typography-modular-base: 1rem;
$typography-modular-6: 1rem;
$typography-modular-5: 1.2rem;
$typography-modular-4: 1.44rem;
$typography-modular-3: 1.728rem;
$typography-modular-2: 2.074rem;
$typography-modular-1: 2.488rem;
$spacing-vertical-base: 20px; //1.333rem; // 20px line-height
// $spacing-vertical-base: 1.571428rem; // 22px line-height
$spacing-vertical-1: $spacing-vertical-base;
$spacing-vertical-1-5: calc(1.5 * $spacing-vertical-base);
$spacing-vertical-2: calc(2 * $spacing-vertical-base);
$spacing-vertical-3: calc(3 * $spacing-vertical-base);

$px-8: 0.5rem;
$px-10: 0.625rem;
$px-30: $spacing-vertical-1-5; // is actually 33px, but matches with modular scale figures for better baselining
// $px-30: 2.14285714rem;
$px-45: 3.214285714rem;
$px-200: 12.5rem;

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

$file-margin: 20px;

$main-green: #3c9c42;
$darker-green: #34933A;
$lighter-green: #69d770;
$main-yellow: #eb9e0d;
$lighter-yellow: #f6bc4d;
$main-red: #b92d2d;
$darker-red: #7E1515;
$lighter-red: #e35a5a;
$light-red: #F7D6E1;
$main-blue: #4c68c7;
$main-blue-dark: #1E2D5A;
$lighter-blue: #7995f4;
$gray: #5B6C7B;

$q90-blue-50: #dfe7ff;

$table-header-bg: #727279;
$table-cell-text: #2e2e34;
$table-cell-bg-light: #F3F3F6;
$table-cell-bg-darker: #E9E9EF;


$neutral-200: #d7d7d7;
$neutral-500: #868686;
$neutral-800: #363636;
$light-500: #d5d5e0;

$tint-20: rgba(#42002d33, 0.2);
$text-70: rgba(255, 255, 255, 0.7);

$margin-xl: 32px;
$margin-l: 16px;
$margin-ml: 16px;
$margin-m: 8px;
$margin-s: 4px;

$q90-010: #293047;
$q90-095: #f2f3f4;
$q90-080: #cfd1d6;
$q90-040: #707484;
$q90-suc: #76b81f;
$q90-pri: #84d11f;
$q90-sec: #467b00;
$q90-war: #eba90f;
$q90-dan: #be1c1c;
$q90-090: #e7e8eb;
$q90-100: #ffffff;
$q90-005: #1d243c;
$q90-060: #a0a3ad;
$q90-000: #111832;
$q90-101: #4D4D53;

$q90-sec: #467b00;
$q90-gra: linear-gradient(to bottom right, $q90-pri, $q90-sec);
$q90-gra-002: linear-gradient(101deg, $q90-pri 0%, $q90-sec 100%) 0% 0%
  no-repeat padding-box;
$q90-gra-003: linear-gradient(135deg, $q90-pri 0%, $q90-sec 100%) 0% 0%
  no-repeat padding-box;

$t1: #ffffff; /* Tint 1  */
$t2: #f2f3f4; /* Tint 2  */
$t3: #e7e8eb; /* Tint 3  */
$t4: #cfd1d6; /* Tint 4  */
$t5: #a0a3ad; /* Tint 5  */
$t6: #707484; /* Tint 6  */
$t7: #41465b; /* Tint 7  */
$t8: #293047; /* Tint 8  */
$t9: #1d243c; /* Tint 9  */
$t10: #D9D9E3;
$t11: #848BA3;
$t12: #CACAD8;
$t13: #87878E;

$standard-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.125);
$menu-shadow: 0px 3px 5px #00000026;
$button-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.125);
$standard-spacer: 1.275rem;
$standard-border-width: 1.5px;
$standard-border-color: $t3;
$standard-border: $standard-border-width solid $standard-border-color;
$standard-border-darker: 1px solid black;
$standard-border-radius: 8px;
$smaller-border-radius: 4px;
$smallest-border-radius: 4px;

$max-width-desktop: 102.429rem;
$header-height: $px-45;
$main-height: calc(100vh - (calc($px-45 + calc($standard-spacer * 2))));
$main-sidebar-width: 21.429rem;
$main-sidebar-width-collapsed: auto;
// $main-sidebar-width-collapsed: 48px;
$main-body-columns: $main-sidebar-width 1fr;
$main-body-columns-collapsed: $main-sidebar-width-collapsed 1fr;
$module-header-height: calc($px-45);

// $main-body-columns: fit-content(18.75rem) 1fr;

div.admin-module-header {
  height: $module-header-height;
}

div.fill-screen-height {
  height: $main-height;
  // min-height: 360px;
}
div.fill-container-height {
  height: calc($main-height - $module-header-height * 3 - $standard-spacer);
}
div.auto-height-header {
  grid-template-rows: auto 1fr;
}

*.color-primary {
  color: $q90-pri;
}

*.color-danger {
  color: $q90-dan;
}

*.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

*.centered-block {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  height: 100%;
}

*.q90-control-border {
  border-radius: 0;
  border-left: $standard-border-width solid $t3;
}

*.q90-control-padding {
  padding: 0 calc($standard-spacer/2);
}

*.q90-divider {
  border-bottom: $standard-border-width solid $t3;
  padding: calc($standard-spacer/2) $standard-spacer;
  margin-block: 0 0;
  min-height: $px-30;
  box-sizing: content-box;
  &.b-0 {
    border-bottom-color: transparent;
  }
}

*.q90-divider-form {
  @extend .q90-divider;
  padding: 0 0 0 $standard-spacer;
  &.mt-1 {
    margin-top: $px-30;
  }
  &.mb-1 {
    margin-bottom: $px-30;
  }
  &.bt-1 {
    border-top: $standard-border-width solid $t3;
  }
}

*.q90-divider-spacing {
  display: grid;
  grid-template-columns: 4fr 8fr;
  gap: 0;
}

*.long-text {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

*.green-marker-body {
  content: "";
  width: $px-30;
  height: $px-30;
  position: absolute;
  left: calc(($px-30/2) * -1);
  border-radius: 50%;
}

*.green-marker-bg {
  background: radial-gradient(transparent, 8px, rgba(255, 255, 255, 0.65) 0),
    linear-gradient(to bottom right, $q90-pri, $q90-sec);
}

*.green-marker-dark-bg {
  background: radial-gradient(transparent, 8px, rgba(0, 0, 0, 0.5) 0),
    linear-gradient(to bottom right, $q90-pri, $q90-sec);
}

*.green-marker::before {
  @extend .green-marker-body;
  @extend .green-marker-bg;
}

*.green-marker-dark::before {
  @extend .green-marker-body;
  @extend .green-marker-dark-bg;
}

*.checkbox-input {
  content: "";
  display: inline-block;
  background-color: $q90-095;
  border-radius: $smallest-border-radius;
  width: calc($spacing-vertical-base/2);
  height: calc($spacing-vertical-base/2);
  line-height: calc($spacing-vertical-base/2);
  padding: 1px;
  vertical-align: middle;
}

div.user-account-menu {
  background-color: $q90-000;
  border-radius: $standard-border-radius;
  padding: 0;
  z-index: 10;
  box-shadow: $menu-shadow;
  border: 0;
  min-width: $px-200;
  text-align: left;
}

*.user-account-menu-dialog {
  content: "";
  position: absolute;
  margin: auto;
  height: $px-8;
  width: $px-8;
  transform: rotate(45deg);
  background-color: $q90-000;
}

ul.navlist {
  li {
    position: relative;
    overflow: hidden;
    height: $px-30;
    line-height: $px-30;
    color: $t5;
    padding-left: calc(($spacing-vertical-base * 1.5) + $standard-spacer);
    padding-right: $standard-spacer;

    &:first-of-type {
      margin-top: calc($px-30/6);
    }

    a {
      display: inline-block;
      color: inherit;
      width: 100%;
      font-weight: 400;

      &.is-active,
      &:hover {
        cursor: pointer;
        color: $q90-100;
      }
    }
    &:has(a.is-active),
    &:hover {
      background-color: $t9;
      color: $t1;
      cursor: pointer;
    }
    &.is-active,
    &:has(a.is-active) {
      @extend .green-marker-dark;
    }
  }
}

div.is-closed {
  display: none;
  -webkit-animation: fadeout 0.175s ease-in alternate 1;
  -moz-animation: fadeout 0.175s ease-in alternate 1;
  animation: fadeout 0.175s ease-in alternate 1;
}

div.is-open {
  display: block;
  -webkit-animation: fadein 0.175s ease-in alternate 1;
  -moz-animation: fadein 0.175s ease-in alternate 1;
  animation: fadein 0.175s ease-in alternate 1;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
