@mixin replace-text($image, $x: 50%, $y: 50%) {
  text-indent: -99999em;
  overflow: hidden;
  text-align: left;
  background: {
    image: $image;
    repeat: no-repeat;
    position: $x $y;
    size: contain;
  }
}

@mixin setPosCenter($x: 50%, $y: 50%) {
  display: block;
  position: absolute;
  top: $x;
  left: $y;
  transform: translate(-$x, -$y);
}

.mail-icon {
  @include replace-text(
    url("https://static.vecteezy.com/system/resources/previews/004/999/412/non_2x/mail-icon-envelope-sign-email-symbol-free-vector.jpg"),
    0,
    0
  );
  display: inline-block;
}

.color-preview {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  border-radius: 50%;
  background: red;
}
