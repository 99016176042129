@use "sass:color";
@use "./variables.scss";

span.primeng-calendar {
  font-family: variables.$font-family;
  width: 100%;
  position: relative;
  input {
    cursor: pointer;
  }
  &.p-focus input {
    box-shadow: none;
  }
  button {
    display: flex;
    right: 0;
    left: auto;
    padding: 0;
    background-color: inherit;
    color: variables.$t6;
    &:hover {
      background-color: inherit;
    }
  }
  button.p-datepicker-trigger {
    position: absolute;
    width: auto;
    padding-right: variables.$standard-spacer / 2;
  }
  .p-calendar {
    min-width: auto;
    width: auto;
  }
  .p-datepicker {
    min-width: auto;
  }
}

.spinner {
  [class*="spinner-white-"] {
    .p-progressspinner-circle {
      stroke: white !important; // only solution to override primeNG with use of [class*=]
    }
  }
  [class*="spinner-blue-"] {
    .p-progressspinner-circle {
      stroke: variables.$main-blue !important; // only solution to override primeNG with use of [class*=]
    }
  }
}
.p-progressspinner {
  &.spinner-white-xsm {
    width: 1.375rem;
    height: 1.375rem;
    line-height: 1.375rem;
    vertical-align: middle;
    .p-progressspinner-circle {
      stroke: variables.$t1;
      animation: p-progressspinner-dash 1.5s ease-in-out infinite;
    }
  }
  &.spinner-green-xsm {
    width: 1.375rem;
    height: 1.375rem;
    line-height: 1.375rem;
    vertical-align: middle;
    .p-progressspinner-circle {
      stroke: variables.$q90-pri;
      animation: p-progressspinner-dash 1.5s ease-in-out infinite;
    }
  }
  &.spinner-white-sm {
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
    vertical-align: middle;
    .p-progressspinner-circle {
      stroke: variables.$t1;
      animation: p-progressspinner-dash 1.5s ease-in-out infinite;
    }
  }
  &.spinner-green-sm {
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
    vertical-align: middle;
    .p-progressspinner-circle {
      stroke: variables.$q90-pri;
      animation: p-progressspinner-dash 1.5s ease-in-out infinite;
    }
  }
  &.spinner-green-md {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    vertical-align: middle;
    .p-progress-spinner-circle {
      stroke: variables.$q90-pri;
      animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
    }
  }
  &.spinner-green-lg {
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
    vertical-align: middle;
    .p-progressspinner-circle {
      stroke: variables.$q90-pri;
      animation: p-progressspinner-dash 1.5s ease-in-out infinite;
    }
  }
}

.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: white;
}


sortalticon::before {
  content:"\1F859";
}

sortamountupalticon::before {
  content: "\1F851";
}

sortamountdownicon::before {
  content: "\1F853";
}

sortalticon,
sortamountupalticon,
sortamountdownicon {
  svg {
    display: none;
  }
}

sortalticon::before,
sortamountupalticon::before,
sortamountdownicon::before {
  font-size: 1.25rem;
  margin-top: -8px;
  margin-left: 0;
  color:white;
}

.tag {
  background-color: variables.$main-blue;
  padding: 4px variables.$standard-spacer / 2;
  color: white;
  font-size: variables.$typography-modular-html-base;
  transition: background-color 300ms ease-in-out;
  border-radius: variables.$smallest-border-radius;
  &:hover{
    background-color: color.adjust(variables.$main-blue, $lightness: -10%);
  }
}

form {
  .p-inputtext:enabled:focus {
    border-color: variables.$main-blue;
  }
  .p-datepicker {
    width: 100%;
  }
}

.p-datepicker {
  .p-button {
    background-color: variables.$main-blue;
    border-color: variables.$main-blue;
    &:not(:disabled):hover {
      color: variables.$main-blue;
      background-color: rgba(variables.$main-blue, .2);
    }
  }
  .p-button-icon-only {
    color: variables.$main-blue;
    background-color: transparent;
    border-color: transparent;
  }
  .p-datepicker-day-selected {
    background-color: variables.$main-blue;
  }
}

table {
  --p-datatable-header-cell-padding: 4px 8px;
  --p-datatable-body-cell-padding: 4px 8px;

  &.p-datatable-table {
    border-radius: variables.$standard-border-radius;
    overflow: hidden;
  }
}
