@use "variables.scss";

::placeholder,
option.placeholder {
  color: variables.$q90-060;
}

.form-label {
  font-weight: 600;
}

.suffixIconInput {
  position: relative;
  [class^='mci-'] {
    position: absolute;
    top: calc(0.5rem + 1px);
    right: 0.5rem;
    z-index: 1;
  }
  .form-control {
    padding-right: 2rem;
  }
}

.prefixIconInput {
  position: relative;
  [class^='mci-'] {
    position: absolute;
    top: calc(0.5rem + 1px);
    left: 0.5rem;
  }
  .form-control {
    padding-left: 2rem;
  }
}

form {
  position: relative;
  background-color: inherit;
  min-width: 24rem;
  &.w-auto {
    min-width: auto;
  }
  fieldset {
    position: relative;
    padding: 0;
    background-color: inherit;
    border: 0;
    &.is-closed {
      display: none;
      visibility: hidden;
    }
    legend {
      font-weight: 500;
    }
  }
  div.form-control-wrapper {
    margin-block: 0 variables.$standard-spacer;
  }
  div.form-control {
    display: block;
    position: relative;
    margin-block: 0 0;
    div.form-label {
      &.flex-layout {
        p {
          white-space: nowrap;
          @extend .my-0;
        }
      }
    }
    label {
      width: 100%;
      font-weight: 600;
      display: block;
      &.disabled {
        // background-color: inherit;
        color: variables.$q90-080;
      }
    }
    input,
    select,
    textarea {
      width: 100%;
      display: block;
      border-radius: variables.$smallest-border-radius;
      padding: 0 variables.$standard-spacer/2;
      border: 0;
      outline: 0;
      &[disabled] {
        background-color: inherit;
        color: variables.$q90-080;
      }
      &:focus:not(input[type="checkbox"]) {
        background-color: variables.$t2;
        border: 0;
      }
    }
    &.form-control-dark {
      label {
        color: variables.$t1;
      }
      input,
      select,
      textarea {
        background-color: variables.$t8;
        color: variables.$t6;
        &:focus {
          background-color: variables.$t8;
        }
      }
      & + div.form-errors {
        p {
          color: variables.$q90-dan;
        }
      }
    }
    &.form-control-q90-flex {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      @extend .q90-divider-form;
      & > * {
        flex-grow: 0;
        flex-shrink: 1;
      }
      & > label {
        width: auto;
        font-weight: 300;
      }
    }
    &.form-control-q90 {
      display: grid;
      grid-template-columns: 4fr 8fr;
      gap: 0;
      @extend .q90-divider-form;
      & > label {
        &:not(.wrap-lines) {
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
          height: variables.$spacing-vertical-1-5;
        }
        &.wrap-lines {
          line-height: 22px;
        }
        padding-right: variables.$standard-spacer;
      }
      & > *:nth-child(2) {
        @extend .q90-control-border;
      }
      & > div.q90-control-padding {
        @extend .q90-control-padding;
      }
      & > div.checkbox-group {
        display: grid;
        gap: 0;
        div.checkbox-item {
          display: flex;
          align-items: center;
          gap: variables.$standard-spacer/3;
          input,
          label {
            width: auto;
            color: variables.$t6;
            font-weight: normal;
            vertical-align: middle;
          }
          input {
            appearance: none;
            padding-left: 0;
            padding-right: 0;
            &::before {
              @extend .checkbox-input;
              border: 1px solid variables.$q90-060;
            }
            &.is-active {
              &::before {
                @extend .checkbox-input;
                background: variables.$q90-gra-003;
                border: 1px solid variables.$q90-pri;
                background-origin: content-box;
              }
            }
          }
        }
      }
      input,
      select,
      textarea {
        height: variables.$spacing-vertical-1-5;
        &.ng-invalid.ng-touched,
        &.ng-invalid.submitted {
          color: variables.$q90-dan;
        }
      }
      textarea {
        resize: none;
        height: variables.$spacing-vertical-1-5 * 5;
      }
      &:has(input[type="file"]):not(.auto-height) {
        height: variables.$spacing-vertical-1-5 * 3;
      }
      &:has(input.search) {
        grid-template-columns: auto 1fr;
        // margin-bottom: $px-30;
        & > label {
          padding-right: variables.$standard-spacer/4;
        }
      }
      & + div.form-errors,
      & + div.form-info {
        display: grid;
        grid-template-columns: 4fr 8fr;
        gap: 0;
        @extend .q90-divider-form;
        align-items: center;
        height: variables.$spacing-vertical-1-5;
        p {
          border-radius: 0;
          border-left: variables.$standard-border-width solid variables.$t3;
          height: variables.$spacing-vertical-1-5;
          line-height: variables.$spacing-vertical-1-5;
          grid-column: 2;
          margin: 0;
          padding: 0 0 0 variables.$standard-spacer/2;
        }
      }
    }
    div.submit-warning {
      display: none;
      position: absolute;
      right: 0;
    }
    div.search-x {
      display: none;
      position: absolute;
      right: 0;
      left: auto;
      top: 0;
      padding-right: variables.$standard-spacer/2;
      line-height: variables.$spacing-vertical-1-5;
      background: inherit;
      &.can-clear-me {
        cursor: pointer;
        display: inline-block;
      }
    }
    &.form-control-inline {
      width: 200px;
      display: inline-block;
      margin-right: 25px;

      label,
      ::placeholder,
      input,
      select {
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0.48px;
        font-size: variables.$margin-l;
        font-style: normal;
      }

      label {
        &:first-of-type {
          color: variables.$neutral-500;
          font-size: 14px;
          line-height: variables.$margin-l;
          letter-spacing: 0.42px;
          margin-bottom: variables.$margin-s;
          margin-left: variables.$margin-m;
        }
        &:not(:first-of-type) {
          color: variables.$neutral-800;
        }
      }

      ::placeholder {
        color: variables.$neutral-200;
      }

      input,
      select {
        height: 40px;
        color: variables.$neutral-800;
      }
      .search-x {
        line-height: 40px;
      }
    }
  }
  div.form-errors {
    p {
      @extend .my-0;
      color: variables.$q90-dan;
    }
  }
  div.form-info {
    p {
      @extend .my-0;
      color: variables.$t5;
    }
  }

  > *:last-child .form-control.form-control-inline {
    margin-right: 0;
  }
}

button,
.button {
  text-decoration: none;
  line-height: 2rem;
  display: inline-block;
  vertical-align: middle;
  border-radius: variables.$smallest-border-radius;
  height: variables.$px-30;
  border: 0;
  padding: 0 variables.$standard-spacer/1.5;
  margin: auto;
  color: variables.$q90-000;
  background-color: variables.$q90-095;
  background-image: none;

  &:not(:disabled):not(.is-active) {
    cursor: pointer;
  }
  &:hover,
  &.is-active {
    background-color: variables.$t3;
  }
  &:disabled {
    cursor: default;
    background-color: variables.$q90-095;
    color: variables.$q90-080;
  }
  &.action {
    background-image: variables.$q90-gra;
    background-color: variables.$q90-100;
    color: variables.$q90-100;
    &:hover {
      opacity: 0.75;
    }
    &:disabled {
      opacity: 0.3;
    }
  }
  &.textual {
    background-image: none;
    background-color: inherit;
    color: variables.$q90-040;
    font-weight: 400;
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      opacity: 0.3;
    }
  }
}

.form-control-label {
  width: 100%;
  font-weight: 600;
  display: block;

  &.disabled {
    // background-color: inherit;
    color: variables.$q90-080;
  }
}
