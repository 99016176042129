@use "../variables";

@for $i from 1 through 12 {
  $val: $i;
  .grid-cols-#{$i} {
    // @media (min-width: 0px) {
    grid-template-columns: repeat($i, minmax(0, 1fr));
    // }
    // @media (min-width: $md) and (max-width: $lg) {
    //   grid-template-columns: repeat(round(calc($i / 2)), minmax(0, 1fr));
    // }
    // @media (min-width: $sm) and (max-width: $md) {
    //   grid-template-columns: repeat($i - round(calc($i / 2)), minmax(0, 1fr));
    // }
    // @media (max-width: $sm) {
    //   display: block;
    // }
  }

  .sm\:grid-cols-#{$i} {
    @media (min-width: variables.$sm) {
      grid-template-columns: repeat($i, minmax(0, 1fr));
    }
  }
  .md\:grid-cols-#{$i} {
    @media (min-width: variables.$md) {
      grid-template-columns: repeat($i, minmax(0, 1fr));
    }
  }
  .lg\:grid-cols-#{$i} {
    @media (min-width: variables.$lg) {
      grid-template-columns: repeat($i, minmax(0, 1fr));
    }
  }
  .xl\:grid-cols-#{$i} {
    @media (min-width: variables.$xl) {
      grid-template-columns: repeat($i, minmax(0, 1fr));
    }
  }

  .grid-span-#{$i} {
    // @media (min-width: $sm) {
    @media (max-width: variables.$sm) {
      grid-column: 1 / #{$i + 1};
    }
  }

  .grid-colspan-#{$i} {
    grid-column: span $i;
  }
  .sm\:grid-colspan-#{$i} {
    @media (min-width: variables.$sm) {
      grid-column: span $i;
    }
  }
  .md\:grid-colspan-#{$i} {
    @media (min-width: variables.$md) {
      grid-column: span $i;
    }
  }
  .lg\:grid-colspan-#{$i} {
    @media (min-width: variables.$lg) {
      grid-column: span $i;
    }
  }
  .xl\:grid-colspan-#{$i} {
    @media (min-width: variables.$xl) {
      grid-column: span $i;
    }
  }
}
