@use "sass:meta";
@use "../../app/styles/mixins/base.scss";
@use "../../app/styles/utils/base.scss" as base2;
@use "../../app/styles/primeng-overrides.scss";
@use "../../app/styles/mci.scss";
@include meta.load-css("../../app/styles/forms.scss");
@include meta.load-css("../../app/styles/modular-scale.scss");

html {
  width: 100vw;
  height: 100dvh;
  position: relative;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}
