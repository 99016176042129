@for $i from 1 through 2000 {
  $val: $i;
  .w-#{$i} {
    width: #{$val + "px"};
    max-width: 100%;
  }
  .h-#{$i} {
    height: #{$val + "px"};
    max-height: 100%;
  }
  .lh-#{$i} {
    line-height: #{$val + "px"};
  }
}
